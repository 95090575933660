.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  pointer-events: none;
}

.show {
  opacity: 1;
  pointer-events: visible;
}

.modButton {
  width: 100%;
  cursor: pointer;
}